import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from "react-bootstrap/Spinner";
import IndexComponentsHeader from "./IndexComponentsHeader";


let currentLevel: number | null = 0;
let allTimeHigh: number | null = 0;
let allTimeLow: number | null = 0;
let annualizedVolatility: number | null = 0;
let riskFreeRate: number | null = 0;
let sharpeRatio: number | null = 0;
let sortinoRatio: number | null = 0;
let annualizedReturn: number | null = 0;
let maxDrawdown: number | null = 0;
let dailyReturn: number | null = 0;
let lastMonthReturn: number | null = 0;
let threeMonthReturn: number | null = 0;
let sixMonthReturn: number | null = 0;
let twelveMonthReturn: number | null = 0;
let ytdReturn: number | null = 0;
let dailyReturnRatioViewable: boolean = false;
let dailyReturnRatio: number | null = 0;

export const PmReturnRatioAccessPredicates = [
    'UserHasAccessToIndexPmReturnRatio',
    'IndexGroupHasAccessToIndexPmReturnRatio'
];
Object.freeze(PmReturnRatioAccessPredicates);

function styling(data) {
    return (isNaN(data) || data >= 0) ? {color: "black"} : {color: "red"};
}

function formatting(data) {
    if (data == null || isNaN(data)) {
        return 'NA'
    } else {
        return data.toFixed(2)
    }
}

interface PerformanceMetricsResponse {
    data: PerformanceMetricsData,
    accesses: string[]
}

interface PerformanceMetricsData {
    currentLevel?: number | null;
    allTimeHigh?: number | null;
    allTimeLow?: number | null;
    annualizedReturn?: number | null;
    annualizedVolatility?: number | null;
    threeMonthReturn?: number | null;
    sixMonthReturn?: number | null;
    twelveMonthReturn?: number | null;
    ytdReturn?: number | null;
    lastMonthReturn?: number | null;
    riskFreeRate?: number | null;
    sortinoRatio?: number | null;
    sharpeRatio?: number | null;
    dailyReturn?: number | null;
    maxDrawdown?: number | null;
    dailyReturnRatio?: number | null;
}


function PerformanceMetrics(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [returnResponse, setReturnResponse] = useState<PerformanceMetricsResponse>();


    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + props.indexCompositeKey + '/returnData')
            .then(res => res.json())
            .then(
                (result) => {
                    setReturnResponse(result as PerformanceMetricsResponse);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);

    if (returnResponse != null && returnResponse.data != null && Object.keys(returnResponse.data).length > 0) {
        const returnData = returnResponse.data
        currentLevel = returnData['currentLevel'] != null ? parseFloat(returnData['currentLevel'].toFixed(2)) : null;
        allTimeHigh = returnData['allTimeHigh'] != null ? parseFloat(returnData['allTimeHigh'].toFixed(2)) : null;
        allTimeLow = returnData['allTimeLow'] != null ? parseFloat(returnData['allTimeLow'].toFixed(2)) : null;

        annualizedVolatility = returnData['annualizedVolatility'] != null ? parseFloat(returnData['annualizedVolatility'].toFixed(2)) : null;
        annualizedReturn = returnData['annualizedReturn'] != null ? parseFloat(returnData['annualizedReturn'].toFixed(2)) : null;
        maxDrawdown = returnData['maxDrawdown'] != null ? parseFloat(returnData['maxDrawdown'].toFixed(2)) : null;

        riskFreeRate = returnData['riskFreeRate'] != null ? parseFloat(returnData['riskFreeRate'].toFixed(2)) : null;
        sharpeRatio = returnData['sharpeRatio'] != null ? parseFloat(returnData['sharpeRatio'].toFixed(2)) : null;
        sortinoRatio = returnData['sortinoRatio'] != null ? parseFloat(returnData['sortinoRatio'].toFixed(2)) : null;
        dailyReturn = returnData['dailyReturn'] != null ? parseFloat(returnData['dailyReturn'].toFixed(2)) : null;

        lastMonthReturn = returnData['lastMonthReturn'] != null ? parseFloat(returnData['lastMonthReturn'].toFixed(2)) : null;
        ytdReturn = returnData['ytdReturn'] != null ? parseFloat(returnData['ytdReturn'].toFixed(2)) : null;
        threeMonthReturn = returnData['threeMonthReturn'] != null ? parseFloat(returnData['threeMonthReturn'].toFixed(2)) : null;
        sixMonthReturn = returnData['sixMonthReturn'] != null ? parseFloat(returnData['sixMonthReturn'].toFixed(2)) : null;
        twelveMonthReturn = returnData['twelveMonthReturn'] != null ? parseFloat(returnData['twelveMonthReturn'].toFixed(2)) : null;

        twelveMonthReturn = returnData['twelveMonthReturn'] != null ? parseFloat(returnData['twelveMonthReturn'].toFixed(2)) : null;

        dailyReturnRatioViewable = returnResponse.accesses != null && returnResponse.accesses
            .some(predicate => PmReturnRatioAccessPredicates.includes(predicate));
        dailyReturnRatio = returnData.dailyReturnRatio != null ? parseFloat(returnData.dailyReturnRatio.toFixed(2)) : null;
    }

    return (
        <Col xs='12' md='6' fluid>
            <IndexComponentsHeader title={"Performance Metrics"}/>
            <Row style={{fontSize: "95%"}}>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "250px"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                    :
                    <>
                        <Col xs="12" md="6">
                            <div>
                                <p className="d-flex"><b className="flex-grow-1">Current Level:</b><span
                                    style={styling(currentLevel)}>{formatting(currentLevel)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">All Time High:</b><span
                                    style={styling(allTimeHigh)}>{formatting(allTimeHigh)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">All Time Low:</b><span
                                    style={styling(allTimeLow)}>{formatting(allTimeLow)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Annualized Volatility:</b><span
                                    style={styling(annualizedVolatility)}>{formatting(annualizedVolatility)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Annualized Return (%):</b><span
                                    style={styling(annualizedReturn)}>{formatting(annualizedReturn)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Sharpe Ratio:</b><span
                                    style={styling(sharpeRatio)}>{formatting(sharpeRatio)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Sortino Ratio:</b><span
                                    style={styling(sortinoRatio)}>{formatting(sortinoRatio)}</span></p>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div>
                                <p className="d-flex"><b className="flex-grow-1">Risk Free Rate (%):</b><span
                                    style={styling(riskFreeRate)}>{formatting(riskFreeRate)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Max Drawdown (%):</b><span
                                    style={styling(maxDrawdown)}>{formatting(maxDrawdown)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Daily Return (%):</b><span
                                    style={styling(dailyReturn)}>{formatting(dailyReturn)}</span></p>
                                {dailyReturnRatioViewable ?
                                    <p className="d-flex"><b className="flex-grow-1">Daily Return Ratio(%):</b><span
                                        style={styling(dailyReturnRatio)}>{formatting(dailyReturnRatio)}</span></p>
                                    : null}
                                <p className="d-flex"><b className="flex-grow-1">Month-to-Date Return (%):</b><span
                                    style={styling(lastMonthReturn)}>{formatting(lastMonthReturn)}</span>
                                </p>
                                <p className="d-flex"><b className="flex-grow-1">3 Month Return (%):</b><span
                                    style={styling(threeMonthReturn)}>{formatting(threeMonthReturn)}</span>
                                </p>
                                <p className="d-flex"><b className="flex-grow-1">6 Month Return (%):</b><span
                                    style={styling(sixMonthReturn)}>{formatting(sixMonthReturn)}</span>
                                </p>
                                <p className="d-flex"><b className="flex-grow-1">12 Month Return (%):</b><span
                                    style={styling(twelveMonthReturn)}>{formatting(twelveMonthReturn)}</span>
                                </p>
                                <p className="d-flex"><b className="flex-grow-1">YTD Return (%):</b><span
                                    style={styling(ytdReturn)}>{formatting(ytdReturn)}</span>
                                </p>
                            </div>
                        </Col>
                    </>
                }
            </Row>
        </Col>
    )
}

export default PerformanceMetrics;
