import React from "react";
import Alert from 'react-bootstrap/Alert'
import {Card} from "react-bootstrap";


function AccessDenied() {
    return (
        <div className="mx-5">
            <Alert style={{backgroundColor: "#db4437", color: "#fafafa"}}>
                Error 403 - Oops, this page is not accessible for you.
            </Alert>

            <p>Try again or contact Deutsche Bank Support:</p>

            <Card className="mb-3">
                <Card.Body>
                    <span className="font-weight-bold">Contact Details</span>
                    <div className="pt-3">
                        <p className="mb-0">DB Index Team</p>
                        <p className="mb-0">Email: <a href="mailto:index.data@db.com">index.data@db.com</a></p>
                        <p className="mb-0">Phone: <a href="tel:+44(0)20 7545 0505">+44(0)20 7545 0505</a></p>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default AccessDenied;
