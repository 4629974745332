import React from "react";
import Row from 'react-bootstrap/Row'
import IndexComponentsHeader from "./IndexComponentsHeader";
import {Col} from "react-bootstrap";

export interface Props {
    description: string;
    descriptionAppendix: string;
};

function IndexDescription(props: Props) {
    return (
        <Col>
            <IndexComponentsHeader title={"Index Description"}/>
            <Row style={{fontSize: "95%"}}>
                <p style={{paddingLeft: "15px"}}><span>{props.description}</span></p>
                <p style={{paddingLeft: "15px"}}><span>{props.descriptionAppendix}</span></p>
            </Row>
        </Col>
    )
}

export default IndexDescription;
