import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import pdfLogo from "../../../assets/img/pdf.png";
import IndexComponentsHeader from "./IndexComponentsHeader";
import {IndexData, Document} from "../IndexPageSpecific";

export interface Props {
    details: IndexData;
    indexCompositeKey: string;
};

const Classification = ({classification}) => {
    return (classification) &&
        <div>
            <p><b>Classification: </b>{classification}</p>
        </div>
};

const BenchmarkFamily = ({classification, benchmarkFamily}) => {
    return (classification || benchmarkFamily) &&
        <div>
            <p><b>Benchmark Family: </b>{benchmarkFamily}</p>
        </div>
};

const IndexDocuments = ({documents}) => {
    return documents.map((row: Document) =>
        <div>
            <a download={row.documentName} target="_blank"
               href={"/dbiq-web/rest/docsnoticesdata/file?" + "id=" + row.id + "&documentType=" + row.documentType}>
                <img alt="PDF" className={"mr-2"} src={pdfLogo} width={20} height={20}/>{row.documentName}
            </a>
        </div>
    )
};

function IndexDetails(props: Props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [historicalInceptionDate, setHistoricalInceptionDate] = useState('');
    const [isLoadedHistoricalInceptionDate, setIsLoadedHistoricalInceptionDate] = useState(false);
    const indexData: IndexData = props.details;

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + props.indexCompositeKey + '/historicalInceptionDate')
            .then(res => res.json())
            .then(
                (result) => {
                    setHistoricalInceptionDate(result);
                    setIsLoadedHistoricalInceptionDate(true);
                },
                (error) => {
                    setIsLoadedHistoricalInceptionDate(false);
                    console.error(error);
                }
            )
    }, []);

    return (
        <Col xs='12' md='6'>
            <IndexComponentsHeader title={"Index Details"}/>
            <Row style={{fontSize: "95%"}}>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "250px"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                    :

                    <div style={{paddingLeft: "15px"}}>
                        <p><b>Index Name: </b><span>{indexData.name}</span></p>
                        <Row>
                            <Col>
                                <Classification classification={indexData.classification}/>
                                <p><b>Base Currency: </b><span>{indexData.defaultCurrency}</span></p>
                                <p><b>Historical Inception Date: </b>
                                    {(!isLoadedHistoricalInceptionDate)
                                        ?
                                        <Spinner animation="border" role="status" variant="primary" size={'sm'}/>
                                        :
                                        <span>{moment(historicalInceptionDate, "YYYYMMDD").format("DD MMMM YYYY")}</span>
                                    }
                                </p>
                                <p><b>Index Live
                                    Date: </b><span> {moment(indexData.launchDate, "YYYYMMDD").format("DD MMMM YYYY")}</span>
                                </p>
                            </Col>
                            <Col>
                                <BenchmarkFamily classification={indexData.classification}
                                                benchmarkFamily={indexData.benchmarkFamily}/>
                                <p><b>Bloomberg
                                    Ticker: </b><span>{(indexData.bbticker === null) ? 'N/A' : indexData.bbticker}</span>
                                </p>
                                {(indexData.reuters !== null) ?
                                    <p><b>Reuters ID: </b><span>{indexData.reuters}</span></p>
                                    :
                                    <></>
                                }
                                <p><b>Documents: </b><IndexDocuments documents={indexData.documents}/>
                                </p>
                            </Col>
                        </Row>
                    </div>
                }
            </Row>
        </Col>
    )
}

export default IndexDetails;
